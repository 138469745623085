// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getProduct = createAsyncThunk('productSliceData/getProduct', async () => {
    try {
        let response = await axios.get('/Product', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getProductOption = createAsyncThunk('productSliceData/getProductOption', async () => {
    try {
        let response = await axios.get('/List?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteProduct = createAsyncThunk('productSliceData/deleteProduct', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Product/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Product deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const createProduct = createAsyncThunk('productSliceData/createProduct', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Product', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Product added successfully.');
        await dispatch(getProduct());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateProduct = createAsyncThunk('productSliceData/updateProduct', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Product/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Product updated successfully.');
        await dispatch(getProduct());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const productSlice = createSlice({
    name: 'productSliceData',
    initialState: {
        product: [],
        productOption: []
    },
    extraReducers: (builder) => {
        builder.addCase(getProduct.fulfilled, (state, action) => {
            state.product = action?.payload;
            return;
        });
        builder.addCase(getProductOption.fulfilled, (state, action) => {
            state.productOption = action?.payload;
            return;
        });
    }
});

export default productSlice.reducer;
