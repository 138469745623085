// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';
import queryString from 'query-string';

//** Toast Import
import { toast } from 'react-toastify';

// get all Over Time
export const getCompOff = createAsyncThunk('getAllCompOff/getCompOff', async (data) => {
    try {
        let response = await axios.post('/CompOffRequest/get-request', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// get my Over Time
export const getMyCompOff = createAsyncThunk('getAllCompOff/getMyCompOff', async (data) => {
    try {
        let response = await axios.post('/CompOffRequest/get-my-request', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCompOffOptions = createAsyncThunk('getAllCompOff/getCompOffOptions', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/CompOffRequest/overtime-by-employeeid?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCompOffTime = createAsyncThunk('getAllCompOff/getCompOffTime', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/CompOffRequest/overtime-by-dates?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// create all Over Time
export const createCompOff = createAsyncThunk('getAllCompOff/createCompOff', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/CompOffRequest/request', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Correction added succesfully.');

        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// create my Over Time
export const createMyCompOff = createAsyncThunk('getAllCompOff/createMyCompOff', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/CompOffRequest/my-request', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'CompOff added succesfully.');

        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// update all Over Time
export const updateCompOff = createAsyncThunk('getAllCompOff/updateCompOff', async (data) => {
    try {
        let response = await axios.put('/CompOffRequest/request/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'CompOff updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// update my Over Time
export const updateMyCompOff = createAsyncThunk('getAllCompOff/updateMyCompOff', async (data) => {
    try {
        let response = await axios.put('/CompOffRequest/my-request/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'CompOff updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// delete any Over Time
export const deleteCompOff = createAsyncThunk('getAllCompOff/deleteCompOff', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/CompOffRequest/request/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'CompOff deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// delete my Over Time
export const deleteMyCompOff = createAsyncThunk('getAllCompOff/deleteMyCompOff', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/CompOffRequest/my-request/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'CompOff deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateRequestRemarks = createAsyncThunk('getAllCompOff/updateRequestRemarks', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/CompOffRequest/request-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCompOffBulkChangeRemarks = createAsyncThunk('getAllLeaveData/updateCompOffBulkChangeRemarks', async (data) => {
    try {
        let response = await axios.put('/CompOffRequest/bulk-request-status', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Remarks updated succesfully.');

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const CompOffSlice = createSlice({
    name: 'getAllCompOff',
    initialState: {
        compOff: [],
        myCompOff: [],
        compOffOption: [],
        compOfftime: []
    },
    reducers: {
        removeCompOff: (state) => {
            state.compOff = [];
        },
        removeMyCompOff: (state) => {
            state.myCompOff = [];
        },
        removeOption: (state) => {
            state.compOffOption = [];
        },
        removeTime: (state) => {
            state.compOfftime = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCompOff.fulfilled, (state, action) => {
            state.compOff = action?.payload;
            return;
        });
        builder.addCase(getMyCompOff.fulfilled, (state, action) => {
            state.myCompOff = action?.payload;
            return;
        });
        builder.addCase(getCompOffOptions.fulfilled, (state, action) => {
            state.compOffOption = action?.payload;
            return;
        });
        builder.addCase(getCompOffTime.fulfilled, (state, action) => {
            state.compOfftime = action?.payload;
            return;
        });
    }
});

export const { removeCompOff, removeTime, removeMyCompOff, removeOption } = CompOffSlice.actions;
export default CompOffSlice.reducer;
