// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';
// ** API Imports
import axios from '@src/service/axios';
import moment from 'moment';

//** Toast Import
import { toast } from 'react-toastify';

// get all Regularization Request
export const getRegularizationRequest = createAsyncThunk('getAllRegularizationData/getRegularizationRequest', async (data) => {
    try {
        const { employeeId, monthNumber, year, requestStatus, ...queryParams } = data;
        const query = queryString.stringify(queryParams);
        let response = await axios.post(
            '/CorrectionInAttendance/get-request?' + query,
            { employeeId, monthNumber, year, requestStatus },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
// get my Regularization Request
export const getMyRegularizationRequest = createAsyncThunk('getAllRegularizationData/getMyRegularizationRequest', async (data) => {
    try {
        const { monthNumber, year, requestStatus, ...queryParams } = data;
        const query = queryString.stringify(queryParams);
        let response = await axios.post(
            '/CorrectionInAttendance/get-my-request?' + query,
            { monthNumber, year, requestStatus },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// create all Regularization Request
export const createRegularization = createAsyncThunk('getAllRegularizationData/createRegularization', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/CorrectionInAttendance/request', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'request added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// create my Regularization Request
export const createMyRegularization = createAsyncThunk('getAllRegularizationData/createMyRegularization', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/CorrectionInAttendance/my-request', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'request added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// update all Regularization Request
export const updateRegularization = createAsyncThunk('getAllRegularizationData/updateRegularization', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/CorrectionInAttendance/request/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Regularization updated succesfully.');

        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// update my Regularization Request
export const updateMyRegularization = createAsyncThunk('getAllRegularizationData/updateMyRegularization', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/CorrectionInAttendance/my-request/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Regularization updated succesfully.');

        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// delete any Regularization Request
export const deleteRegularization = createAsyncThunk('getAllRegularizationData/deleteRegularization', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/CorrectionInAttendance/request/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Regularization deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// delete my Regularization Request
export const deleteMyRegularization = createAsyncThunk(
    'getAllRegularizationData/deleteMyRegularization',
    async (deleteId, { dispatch }) => {
        try {
            let response = await axios.delete('/CorrectionInAttendance/my-request/' + deleteId, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Regularization deleted successfully.');
            return Promise.resolve(response);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            console.log('err', err);
            return Promise.reject(err);
        }
    }
);

export const regularizationSlice = createSlice({
    name: 'getAllRegularizationData',
    initialState: {
        regularization: [],
        myRegularization: []
    },
    reducers: {
        removeRegularization: (state) => {
            state.regularization = [];
        },
        removeMyRegularization: (state) => {
            state.myRegularization = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRegularizationRequest.fulfilled, (state, action) => {
            state.regularization = action?.payload;
            return;
        });
        builder.addCase(getMyRegularizationRequest.fulfilled, (state, action) => {
            state.myRegularization = action?.payload;
            return;
        });
    }
});

export const { removeRegularization, removeMyRegularization } = regularizationSlice.actions;
export default regularizationSlice.reducer;
