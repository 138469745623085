// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getDisposition = createAsyncThunk('dispositionSliceData/getDisposition', async () => {
    try {
        let response = await axios.get('/Disposition', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getDispositionOption = createAsyncThunk('dispositionSliceData/getDispositionOption', async () => {
    try {
        let response = await axios.get('/Disposition?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteDisposition = createAsyncThunk('dispositionSliceData/deleteDisposition', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Disposition/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Disposition deleted successfully.');
        await dispatch(getDisposition());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const createDisposition = createAsyncThunk('dispositionSliceData/createDisposition', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Disposition', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Disposition added successfully.');
        await dispatch(getDisposition());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDisposition = createAsyncThunk('dispositionSliceData/updateDisposition', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Disposition/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Disposition updated successfully.');
        await dispatch(getDisposition());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const dispositionSlice = createSlice({
    name: 'dispositionSliceData',
    initialState: {
        disposition: [],
        dispositionOption: []
    },
    extraReducers: (builder) => {
        builder.addCase(getDisposition.fulfilled, (state, action) => {
            state.disposition = action?.payload;
            return;
        });
        builder.addCase(getDispositionOption.fulfilled, (state, action) => {
            state.dispositionOption = action?.payload;
            return;
        });
    }
});

export default dispositionSlice.reducer;
