// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getFeeds = createAsyncThunk('feedsSliceData/getEmployees', async (data) => {
    try {
        let response = await axios.get('/feeds?postCategory=' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createFeeds = createAsyncThunk('feedsSliceData/createFeeds', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/feeds', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Feeds added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateFeeds = createAsyncThunk('feedsSliceData/updateFeeds', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/feeds/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Asset updated succesfully.');
        await dispatch(getAsset());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteFeeds = createAsyncThunk('feedsSliceData/deleteFeeds', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/feeds/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Asset deleted successfully.');
        // await dispatch(getAsset());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const addReaction = createAsyncThunk('feedsSliceData/addReaction', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/feeds/reactions/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Asset updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const addComment = createAsyncThunk('feedsSliceData/addComment', async (data) => {
    try {
        let response = await axios.put('/feeds/comment', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Asset updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateFeedStatus = createAsyncThunk('feedsSliceData/updateFeedStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/feeds/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(getBranch());
        toast.success(response?.data?.message || 'Status updated successfully !');
        return Promise.resolve(response);
    } catch {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const feedsSlice = createSlice({
    name: 'feedsSliceData',
    initialState: {
        feeds: []
    },
    extraReducers: (builder) => {
        builder.addCase(getFeeds.fulfilled, (state, action) => {
            state.feeds = action?.payload;
            return;
        });
    }
});

export default feedsSlice.reducer;
