// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

// ** Toast Imports
import { toast } from 'react-toastify';

export const getShift = createAsyncThunk('shiftSliceData/getShift', async () => {
    try {
        let response = await axios.get('/Shift', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getShiftOption = createAsyncThunk('shiftSliceData/getShiftOption', async () => {
    try {
        let response = await axios.get('/Shift?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteShift = createAsyncThunk('shiftSliceData/deleteShift', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Shift/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Shift deleted successfully.');
        await dispatch(getShift());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createShift = createAsyncThunk('shiftSliceData/createShift', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Shift', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Shift added succesfully.');
        await dispatch(getShift());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateShift = createAsyncThunk('shiftSliceData/updateShift', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Shift/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Shift updated succesfully.');
        await dispatch(getShift());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateShiftStatus = createAsyncThunk('shiftSliceData/updateShiftStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/Shift/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getShift());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const shiftSlice = createSlice({
    name: 'shiftSliceData',
    initialState: {
        shift: [],
        shiftOption: []
    },
    extraReducers: (builder) => {
        builder.addCase(getShift.fulfilled, (state, action) => {
            state.shift = action?.payload;
            return;
        });
        builder.addCase(getShiftOption.fulfilled, (state, action) => {
            state.shiftOption = action?.payload;
            return;
        });
    }
});

export default shiftSlice.reducer;
