// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getIncomeCategory = createAsyncThunk('incomeCategorySliceData/getIncomeCategory', async () => {
    try {
        let response = await axios.get('/IncomeCategory', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getIncomeCategoryOption = createAsyncThunk('incomeCategorySliceData/getIncomeCategoryOption', async () => {
    try {
        let response = await axios.get('/IncomeCategory?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteIncomeCategory = createAsyncThunk('incomeCategorySliceData/deleteIncomeCategory', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/IncomeCategory/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'IncomeCategory deleted successfully.');
        await dispatch(getIncomeCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createIncomeCategory = createAsyncThunk('incomeCategorySliceData/createIncomeCategory', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/IncomeCategory', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'IncomeCategory added succesfully.');
        await dispatch(getIncomeCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateIncomeCategory = createAsyncThunk('incomeCategorySliceData/updateIncomeCategory', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/IncomeCategory/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'IncomeCategory updated succesfully.');
        await dispatch(getIncomeCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateIncomeCategoryStatus = createAsyncThunk(
    'incomeCategorySliceData/updateIncomeCategoryStatus',
    async (id, { dispatch }) => {
        try {
            let response = await axios.put('/IncomeCategory/status/' + id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Status Updated Succesfully');
            await dispatch(getIncomeCategory());
            return Promise.resolve(response);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            console.log('err', err);
            return Promise.reject(err);
        }
    }
);
export const incomeCategorySlice = createSlice({
    name: 'incomeCategorySliceData',
    initialState: {
        incomeCategory: [],
        incomeCategoryOption: []
    },
    extraReducers: (builder) => {
        builder.addCase(getIncomeCategory.fulfilled, (state, action) => {
            state.incomeCategory = action?.payload;
            return;
        });
        builder.addCase(getIncomeCategoryOption.fulfilled, (state, action) => {
            state.incomeCategoryOption = action?.payload;
            return;
        });
    }
});

export default incomeCategorySlice.reducer;
