// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

import queryString from 'query-string';

export const getMonthlyActivity = createAsyncThunk('manualAttendanceSliceData/getMonthlyActivity', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/Attendance/month-activity?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getManualPunch = createAsyncThunk('manualAttendanceSliceData/getManualPunch', async () => {
    try {
        let response = await axios.get('/Attendance/manual-punch', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getInPunch = createAsyncThunk('manualAttendanceSliceData/getInPunch', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/Attendance/in-punch?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const createManualAttendance = createAsyncThunk('manualAttendanceSliceData/createManualAttendance', async (data) => {
    try {
        let response = await axios.post('/Attendance/manual-punch', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Punch added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const manualAttendanceSlice = createSlice({
    name: 'manualAttendanceSliceData',
    initialState: {
        monthlyActivity: {},
        inPunch: {},
        manualPunch: []
    },
    extraReducers: (builder) => {
        builder.addCase(getMonthlyActivity.fulfilled, (state, action) => {
            state.monthlyActivity = action?.payload;
            return;
        });
        builder.addCase(getInPunch.fulfilled, (state, action) => {
            state.inPunch = action?.payload;
            return;
        });
        builder.addCase(getManualPunch.fulfilled, (state, action) => {
            state.manualPunch = action?.payload;
            return;
        });
    }
});

export default manualAttendanceSlice.reducer;
