// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getRoleByMultiProfile = createAsyncThunk('MultiProfileSliceData/getRoleByMultiProfile', async () => {
    try {
        let response = await axios.get('/Authentication/multi-profile-role', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(JSON.parse(response?.data));
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const multiProfileLogin = createAsyncThunk('MultiProfileSliceData/multiProfileLogin', async (id) => {
    try {
        let response = await axios.get('/Authentication/multi-profile-login?RoleId=' + id?.id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // await dispatch(getAllowance());
        return Promise.resolve(response);
        // toast.success(response?.data?.message || 'Login successfully.');
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const multiProfileSlice = createSlice({
    name: 'MultiProfileSliceData',
    initialState: {
        roleByMultiProfile: []
    },
    extraReducers: (builder) => {
        builder.addCase(getRoleByMultiProfile.fulfilled, (state, action) => {
            state.roleByMultiProfile = action?.payload;
            return;
        });
    }
});

export default multiProfileSlice.reducer;
