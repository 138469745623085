//** Area Api

import { createAsyncThunk } from '@reduxjs/toolkit';
// Axios Imports
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
export const getArea = createAsyncThunk('locationSliceData/getArea', async () => {
    try {
        let response = await axios.get('/Area', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteArea = createAsyncThunk('locationSliceData/deleteArea', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Area/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Area deleted successfully.');
        await dispatch(getArea());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createArea = createAsyncThunk('locationSliceData/createArea', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Area', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Area added succesfully.');
        await dispatch(getArea());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateArea = createAsyncThunk('locationSliceData/updateArea', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Area/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Area updated succesfully.');
        await dispatch(getArea());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateAreaStatus = createAsyncThunk('locationSliceData/updateAreaStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/Area/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || ' Status updated successfully !');
        dispatch(getArea());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
