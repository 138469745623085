//** Country Api

import { createAsyncThunk } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
export const getCountries = createAsyncThunk('locationSliceData/getCountries', async () => {
    try {
        let response = await axios.get('/Country', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteCountry = createAsyncThunk('locationSliceData/deleteCountry', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Country/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Country deleted successfully.');
        await dispatch(getCountries());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createCountry = createAsyncThunk('locationSliceData/createCountry', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Country', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Country added succesfully.');
        await dispatch(getCountries());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCountry = createAsyncThunk('locationSliceData/updateCountry', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Country/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Country updated succesfully.');
        await dispatch(getCountries());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCountryStatus = createAsyncThunk('locationSliceData/updateCountryStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/Country/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || ' Status updated successfully !');
        dispatch(getCountries());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
