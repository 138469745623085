// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getLeaveRule = createAsyncThunk('getAllLeaveRules/getLeaveRule', async () => {
    try {
        let response = await axios.get('/LeaveRules', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getLeaveRuleOption = createAsyncThunk('getAllLeaveRules/getLeaveRuleOption', async () => {
    try {
        let response = await axios.get('/LeaveRules?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteLeaveRule = createAsyncThunk('getAllLeaveRules/deleteLeaveRule', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/LeaveRules/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Document deleted successfully.');
        await dispatch(getLeaveRule());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createLeaveRule = createAsyncThunk('getAllLeaveRules/createLeaveRule', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/LeaveRules', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Document added succesfully.');
        await dispatch(getLeaveRule());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateLeaveRule = createAsyncThunk('getAllLeaveRules/updateLeaveRule', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/LeaveRules/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Document updated succesfully.');
        await dispatch(getLeaveRule());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const LeaveRuleSlice = createSlice({
    name: 'getAllLeaveRules',
    initialState: {
        leaveRule: [],
        leaveRuleOption: []
    },
    extraReducers: (builder) => {
        builder.addCase(getLeaveRule.fulfilled, (state, action) => {
            state.leaveRule = action?.payload;
            return;
        });
        builder.addCase(getLeaveRuleOption.fulfilled, (state, action) => {
            state.leaveRuleOption = action?.payload;
            return;
        });
    }
});

export default LeaveRuleSlice.reducer;
