// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getAttendance = createAsyncThunk('getAllAttendanceData/getAttendance', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/Attendance/admin-side-attendance?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(JSON.parse(response?.data));
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLockAttendance = createAsyncThunk('getAllAttendanceData/getLockAttendance', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/AttendanceLock?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmpAttendance = createAsyncThunk('getEmpAllAttendanceData/getEmpAttendance', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/Attendance/employee-side-attendance?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(JSON.parse(response?.data));
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const createcorrection = createAsyncThunk('requestSliceData/createcorrection', async (data) => {
    try {
        let response = await axios.post('/CorrectionInAttendance/employee-side', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'request added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//** lock attedance
export const createlockattedance = createAsyncThunk('requestSliceData/createlockattedance', async (data) => {
    try {
        let response = await axios.post('/AttendanceLock', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'lock request added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updatelockattedance = createAsyncThunk('requestSliceData/createlockattedance', async (data) => {
    try {
        let response = await axios.put('/AttendanceLock', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'lock request added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getlockattedance = createAsyncThunk('requestSliceData/getlockattedance', async (data) => {
    try {
        let response = await axios.get('/AttendanceLock', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'lock request added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const attendanceSlice = createSlice({
    name: 'getAllAttendanceData',
    name: 'getEmpAllAttendanceData',

    initialState: {
        attendance: [],
        lockAttendance: []
    },
    reducers: {
        removeAttendance: (state) => {
            state.attendance = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAttendance.fulfilled, (state, action) => {
            state.attendance = action?.payload;
            return;
        });
        builder.addCase(getEmpAttendance.fulfilled, (state, action) => {
            state.attendance = action?.payload;
            return;
        });
        builder.addCase(getLockAttendance.fulfilled, (state, action) => {
            state.lockAttendance = action?.payload;
            return;
        });
    }
});

export const { removeAttendance } = attendanceSlice.actions;
export default attendanceSlice.reducer;
