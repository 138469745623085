// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getFaq = createAsyncThunk('faqSliceData/getFaq', async () => {
    try {
        let response = await axios.get('/Faq', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getFaqCategoryOption = createAsyncThunk('faqSliceData/getFaqCategoryOption', async () => {
    try {
        let response = await axios.get('/FaqCategory?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteFaq = createAsyncThunk('faqSliceData/deleteFaq', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Faq/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'FaqCategory deleted successfully.');
        await dispatch(getFaq());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createFaq = createAsyncThunk('faqSliceData/createFaq', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Faq', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'FaqCategory added succesfully.');
        await dispatch(getFaq());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateFaq = createAsyncThunk('faqSliceData/updateFaq', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Faq/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'FaqCategory updated succesfully.');
        await dispatch(getFaq());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateFaqStatus = createAsyncThunk('faqSliceData/updateFaqStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/Faq/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getFaq());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const faqSlice = createSlice({
    name: 'faqSliceData',
    initialState: {
        faq: []
    },
    extraReducers: (builder) => {
        builder.addCase(getFaq.fulfilled, (state, action) => {
            state.faq = action?.payload;
            return;
        });
    }
});

export default faqSlice.reducer;
