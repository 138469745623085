// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

//** get Salary YtdReport api
export const getYtdReport = createAsyncThunk('getAllYtdReportData/getYtdReport', async (data) => {
    try {
        let response = await axios.post('/Salary/ytd-report', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        let temp = JSON.parse(response.data);

        return Promise.resolve(temp);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const ytdReportSlice = createSlice({
    name: 'getAllYtdReportData',

    initialState: {
        ytdReport: []
    },
    reducers: {
        removeYtdReport: (state) => {
            state.ytdReport = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getYtdReport.fulfilled, (state, action) => {
            state.ytdReport = action?.payload;
            return;
        });
    }
});

export const { removeYtdReport } = ytdReportSlice.actions;

export default ytdReportSlice.reducer;
