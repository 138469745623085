//** State Api

import { createAsyncThunk } from '@reduxjs/toolkit';
// Axios Imports
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
export const getState = createAsyncThunk('locationSliceData/getState', async () => {
    try {
        let response = await axios.get('/State', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteState = createAsyncThunk('locationSliceData/deleteState', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/State/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'State deleted successfully.');
        await dispatch(getState());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createState = createAsyncThunk('locationSliceData/createState', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/State', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'State added succesfully.');
        await dispatch(getState());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateState = createAsyncThunk('locationSliceData/updateState', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/State/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'State updated succesfully.');
        await dispatch(getState());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateStateStatus = createAsyncThunk('locationSliceData/updateStateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/State/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || ' Status updated successfully !');
        await dispatch(getState());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
