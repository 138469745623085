// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getAuditLog2 = createAsyncThunk('getAllAuditLogData/getAuditLog2', async (data) => {
    const query = data;
    try {
        let response = await axios.get('/SystemSetting/user-activity-log' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getAuditLogDetails = createAsyncThunk('getAllAuditLogData/getAuditLogDetails', async (data) => {
    try {
        let response = await axios.get('/SystemSetting/user-activity-details/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getUserOption = createAsyncThunk('getAllAuditLogData/getUserOption', async () => {
    try {
        let response = await axios.get('/User?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const auditLog2Slice = createSlice({
    name: 'getAllAuditLogData',

    initialState: {
        auditLog: [],
        auditLogDetail: [],
        userOption: []
    },
    reducers: {
        removeAuditLog: (state) => {
            state.auditLog = [];
        },
        removeUserOption: (state) => {
            state.userOption = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAuditLog2.fulfilled, (state, action) => {
            state.auditLog = action?.payload;
            return;
        });
        builder.addCase(getAuditLogDetails.fulfilled, (state, action) => {
            state.auditLogDetail = action?.payload;
            return;
        });
        builder.addCase(getUserOption.fulfilled, (state, action) => {
            state.userOption = action?.payload;
            return;
        });
    }
});

export const { removeAuditLog, removeUserOption } = auditLog2Slice.actions;
export default auditLog2Slice.reducer;
