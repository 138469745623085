import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const createSalarySlip = createAsyncThunk('salarySlipData/createSalarySlip', async (data) => {
    try {
        let response = await axios.post('/Salary/salary-slip', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Salary Slip added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getEmployeeByDepartment = createAsyncThunk('salarySlipData/getEmployeeByDepartment', async (data) => {
    try {
        let response = await axios.post('/LocationCascade/employee-by-department', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getAllEmployeeSalarySlipData = createAsyncThunk('salarySlipData/getAllEmployeeSalarySlipData', async (data) => {
    try {
        let response = await axios.get('/Salary/salary-slip-zips', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getAllEmployeeData = createAsyncThunk('salarySlipData/getAllEmployeeData', async (id) => {
    try {
        let response = await axios.get('/Salary/all-paid-salary-slip?' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const salarySlipSlice = createSlice({
    name: 'getAllSalarySlipData',

    initialState: {
        salarySlip: [],
        salarySlipZip: []
    },
    extraReducers: (builder) => {
        builder.addCase(getEmployeeByDepartment.fulfilled, (state, action) => {
            state.salarySlip = action?.payload;
            return;
        });
        builder.addCase(getAllEmployeeSalarySlipData.fulfilled, (state, action) => {
            state.salarySlipZip = action?.payload;
            return;
        });
    }
});

export default salarySlipSlice.reducer;
