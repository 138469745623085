// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** axios Imports
import axios from '@src/service/axios';

// ** Query String Imports
import queryString from 'query-string';

//** Toast Import
import { toast } from 'react-toastify';

// all correction request
export const getCorrectionRequest = createAsyncThunk('getAllAttendanceCorrectionData/getCorrectionRequest', async (data) => {
    try {
        const { employeeId, monthNumber, year, requestStatus, ...queryParams } = data;
        const query = queryString.stringify(queryParams);
        let response = await axios.post(
            '/CorrectionInAttendance/get-request?' + query,
            { employeeId, monthNumber, year, requestStatus },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// my correction request
export const getMyCorrectionRequest = createAsyncThunk('getAllAttendanceCorrectionData/getMyCorrectionRequest', async (data) => {
    try {
        const { monthNumber, year, requestStatus, ...queryParams } = data;
        const query = queryString.stringify(queryParams);
        let response = await axios.post(
            '/CorrectionInAttendance/get-my-request?' + query,
            { monthNumber, year, requestStatus },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCorrectionRequestOption = createAsyncThunk('getAllAttendanceCorrectionData/getCorrectionRequestOption', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/CorrectionInAttendance/inout-time?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// create all correction request
export const createCorrectionRequest = createAsyncThunk(
    'getAllAttendanceCorrectionData/createCorrectionRequest',
    async (data, { dispatch }) => {
        try {
            let response = await axios.post('/CorrectionInAttendance/request', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Correction added succesfully.');

            return Promise.resolve(response);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

// create my correction request
export const createMyCorrectionRequest = createAsyncThunk(
    'getAllAttendanceCorrectionData/createMyCorrectionRequest',
    async (data, { dispatch }) => {
        try {
            let response = await axios.post('/CorrectionInAttendance/my-request', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Correction added succesfully.');

            return Promise.resolve(response);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

//**  update all correction request
export const updateCorrectionRequest = createAsyncThunk('getAllAttendanceCorrectionData/updateCorrectionRequest', async (data) => {
    try {
        let response = await axios.put('/CorrectionInAttendance/request/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Correction updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//** update my correction request
export const updateMyCorrectionRequest = createAsyncThunk('getAllAttendanceCorrectionData/updateMyCorrectionRequest', async (data) => {
    try {
        let response = await axios.put('/CorrectionInAttendance/my-request/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Correction updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//** delete any correction request
export const deleteCorrectionRequest = createAsyncThunk(
    'getAllAttendanceCorrectionData/deleteCorrectionRequest',
    async (deleteId, { dispatch }) => {
        try {
            let response = await axios.delete('/CorrectionInAttendance/request/' + deleteId, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Correction deleted successfully.');
            return Promise.resolve(response);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            console.log('err', err);
            return Promise.reject(err);
        }
    }
);

// ** delete my correction request

export const deleteMyCorrectionRequest = createAsyncThunk(
    'getAllAttendanceCorrectionData/deleteMyCorrectionRequest',
    async (deleteId, { dispatch }) => {
        try {
            let response = await axios.delete('/CorrectionInAttendance/my-request/' + deleteId, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Correction deleted successfully.');
            return Promise.resolve(response);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            console.log('err', err);
            return Promise.reject(err);
        }
    }
);

export const updateRequestRemarks = createAsyncThunk('getAllAttendanceCorrectionData/updateRequestRemarks', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/CorrectionInAttendance/request-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const attendanceCorrectionSlice = createSlice({
    name: 'getAllAttendanceCorrectionData',
    initialState: {
        correction: [],
        myCorrection: [],
        correctionRequestOption: {}
    },
    reducers: {
        removeCorrection: (state) => {
            state.correction = [];
        },
        removeMyCorrection: (state) => {
            state.myCorrection = [];
        },
        removeTime: (state) => {
            state.correctionRequestOption = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCorrectionRequest.fulfilled, (state, action) => {
            state.correction = action?.payload;
            return;
        });
        builder.addCase(getMyCorrectionRequest.fulfilled, (state, action) => {
            state.myCorrection = action?.payload;
            return;
        });
        builder.addCase(getCorrectionRequestOption.fulfilled, (state, action) => {
            state.correctionRequestOption = action?.payload;
            return;
        });
    }
});

export const { removeCorrection, removeMyCorrection, removeTime } = attendanceCorrectionSlice.actions;
export default attendanceCorrectionSlice.reducer;
