// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

// get all Shift Request
export const getShiftRequest = createAsyncThunk('/shiftChangeSliceData/getShiftRequest', async (data) => {
    try {
        // const { employeeId, startDate, endDate } = data;
        let response = await axios.post('/ShiftChange/get-request', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// get my Shift Request
export const getMyShiftRequest = createAsyncThunk('/shiftChangeSliceData/getMyShiftRequest', async (data) => {
    try {
        let response = await axios.post('/ShiftChange/get-my-request?', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// create all Shift Request
export const createShiftRequest = createAsyncThunk('shiftChangeSliceData/createShiftRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/ShiftChange/request', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'ShiftChange added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// create my Shift Request
export const createMyShiftRequest = createAsyncThunk('shiftChangeSliceData/createMyShiftRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/ShiftChange/my-request', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'ShiftChange added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// update all Shift Request
export const updateShiftRequest = createAsyncThunk('shiftChangeSliceData/updateShiftRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/ShiftChange/request/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'ShiftChange added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// update my Shift Request
export const updateMyShiftRequest = createAsyncThunk('shiftChangeSliceData/updateMyShiftRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/ShiftChange/my-request/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'ShiftChange added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// delete any Shift Request
export const deleteShiftRequest = createAsyncThunk('shiftChangeSliceData/deleteShiftRequest', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/ShiftChange/request/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'ShiftChange added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// delete my Shift Request
export const deleteMyShiftRequest = createAsyncThunk('shiftChangeSliceData/deleteMyShiftRequest', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/ShiftChange/my-request/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'ShiftChange added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getShiftOption = createAsyncThunk('shiftChangeSliceData/getShiftOption', async () => {
    try {
        let response = await axios.get('/Shift?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateShiftChangeRemark = createAsyncThunk('shiftChangeSliceData/updateShiftChangeRemark', async (data, { dispatch }) => {
    try {
        let response = await axios.put('ShiftChange/request-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateShiftBulkChangeRemark = createAsyncThunk('shiftChangeSliceData/updateShiftBulkChangeRemark', async (data) => {
    try {
        let response = await axios.put('ShiftChange/bulk-request-status', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const shiftChangeSlice = createSlice({
    name: 'shiftChangeSliceData',
    initialState: {
        shiftRequest: [],
        myShiftRequest: [],
        getShiftOption: []
        // shiftChangeRemark:[]
    },
    reducers: {
        removeShiftChange: (state) => {
            state.shiftRequest = [];
        },
        removeMyShiftChange: (state) => {
            state.myShiftRequest = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getShiftRequest.fulfilled, (state, action) => {
            state.shiftRequest = action?.payload;
            return;
        });
        builder.addCase(getMyShiftRequest.fulfilled, (state, action) => {
            state.myShiftRequest = action?.payload;
            return;
        });
        builder.addCase(getShiftOption.fulfilled, (state, action) => {
            state.getShiftOption = action?.payload;
            return;
        });
    }
});
export const { removeShiftChange, removeMyShiftChange } = shiftChangeSlice.actions;
export default shiftChangeSlice.reducer;
