// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

//** get Salary Performance api
export const getPerformance = createAsyncThunk('getAllPerformanceData/getPerformance', async (data) => {
    try {
        let response = await axios.post('/EmployeeEvaluation/performance-report', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const performanceSlice = createSlice({
    name: 'getAllPerformanceData',

    initialState: {
        performance: []
    },
    extraReducers: (builder) => {
        builder.addCase(getPerformance.fulfilled, (state, action) => {
            state.performance = action?.payload;
            return;
        });
    }
});

export default performanceSlice.reducer;
