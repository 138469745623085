// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

// ** Toast Import
import { toast } from 'react-toastify';

export const getDevice = createAsyncThunk('getAllDeviceData/getDevice', async () => {
    try {
        let response = await axios.get('/Device', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteDevice = createAsyncThunk('getAllDeviceData/deleteDevice', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Device/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Device deleted successfully.');
        await dispatch(getDevice());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createDevice = createAsyncThunk('getAllDeviceData/createDevice', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Device', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Device added succesfully.');
        await dispatch(getDevice());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDevice = createAsyncThunk('getAllDeviceData/updateDevice', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Device/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Device added succesfully.');
        await dispatch(getDevice());
        return Promise.resolve(response);
    } catch {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateDeviceStatus = createAsyncThunk('getAllDeviceData/updateDeviceStatus', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Device/statusbybranch', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getDevice());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const downloadTxtFile = createAsyncThunk('getAllDeviceData/downloadTxtFile', async (id) => {
    try {
        let response = await axios.get('/Device/gettxtfile/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const downloadExeFile = createAsyncThunk('getAllDeviceData/downloadExeFile', async () => {
    try {
        let response = await axios.get('/Device/download-exe', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deviceSlice = createSlice({
    name: 'getAllDeviceData',
    initialState: {
        device: []
    },
    extraReducers: (builder) => {
        builder.addCase(getDevice.fulfilled, (state, action) => {
            state.device = action?.payload;
            return;
        });
    }
});

export default deviceSlice.reducer;
