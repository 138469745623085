// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Axios Imports
import axios from '@src/service/axios';

// import { getPermissionsByToken } from '../service';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

// auth config import
import authConfig from '@src/auth/config.js';

// secure ls
import { secureGet, secureSet } from '@src/service/secureLs';

const initialPermission = () => {
    const data = secureGet(authConfig.storagePermissionKeyName);
    if (data) return data;
    return [];
};

export const getPermission = createAsyncThunk('permission/getPermission', async () => {
    try {
        showLoader(true);
        const res = await axios.get('/permission', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        secureSet(
            authConfig.storagePermissionKeyName,
            res.data.map((m1) => m1.moduleId)
        );
        // return res.data.map((m1) => m1.moduleId);
        return res.data;
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return [];
    }
});

export const permissionSlice = createSlice({
    name: 'permission',
    initialState: {
        permission: initialPermission()
    },
    reducers: {
        handlePermissionChange: (state, action) => {
            state.permission = action.payload.map((m1) => m1.moduleId);
        },
        removePermission: (state, action) => {
            state.permission = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPermission.fulfilled, (state, action) => {
            permissionSlice.caseReducers.handlePermissionChange(state, action);
        });
    }
});

export const { handlePermissionChange, removePermission } = permissionSlice.actions;

export default permissionSlice.reducer;
