// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getDeviceUsage = createAsyncThunk('getAlldeviceUsage/getDeviceUsage', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/ScreenTracker/device-usage?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getUserOption = createAsyncThunk('getAlldeviceUsage/getUserOption', async () => {
    try {
        let response = await axios.get('/User?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deviceUsageSlice = createSlice({
    name: 'getAlldeviceUsage',

    initialState: {
        deviceUsage: [],
        userOption: []
    },
    reducers: {
        removeDeviceUsage: (state) => {
            state.deviceUsage = [];
        },
        removeUserOption: (state) => {
            state.userOption = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDeviceUsage.fulfilled, (state, action) => {
            state.deviceUsage = action?.payload;
            return;
        });
        builder.addCase(getUserOption.fulfilled, (state, action) => {
            state.userOption = action?.payload;
            return;
        });
    }
});

export const { removeDeviceUsage, removeUserOption } = deviceUsageSlice.actions;
export default deviceUsageSlice.reducer;
