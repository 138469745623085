// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getEmployees = createAsyncThunk('assetSliceData/getEmployees', async () => {
    try {
        let response = await axios.get('/Employee/all-employee', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getAsset = createAsyncThunk('assetSliceData/getAsset', async () => {
    try {
        let response = await axios.get('/Asset', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const createAsset = createAsyncThunk('assetSliceData/createAsset', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Asset', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Asset added succesfully.');
        await dispatch(getAsset());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateAsset = createAsyncThunk('assetSliceData/updateAsset', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Asset/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Asset updated succesfully.');
        await dispatch(getAsset());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteAsset = createAsyncThunk('assetSliceData/deleteAsset', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Asset/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Asset deleted successfully.');
        await dispatch(getAsset());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateAssetStatus = createAsyncThunk('assetSliceData/updateAssetStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/Asset/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        dispatch(getAsset());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getAssetRemarks = createAsyncThunk('assetSliceData/getAssetRemarks', async (id) => {
    try {
        let response = await axios.get('/Asset/remarks-history/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateAssetRemarks = createAsyncThunk('assetSliceData/updateAssetRemarks', async (data) => {
    try {
        let response = await axios.put('/Asset/asset-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Remarks updated succesfully.');

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const assetSlice = createSlice({
    name: 'assetSliceData',
    initialState: {
        asset: [],
        assetRemarks: []
    },
    extraReducers: (builder) => {
        builder.addCase(getAsset.fulfilled, (state, action) => {
            state.asset = action?.payload;
            return;
        });

        builder.addCase(getEmployees.fulfilled, (state, action) => {
            state.employees = action?.payload;
            return;
        });
        builder.addCase(getAssetRemarks.fulfilled, (state, action) => {
            state.assetRemarks = action?.payload;
            return;
        });
    }
});

export default assetSlice.reducer;
