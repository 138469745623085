// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getAppTimeTrack = createAsyncThunk('getAllAppTimeTrack/getAppTimeTrack', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/ScreenTracker/app-time-tracker?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getUserOption = createAsyncThunk('getAllAppTimeTrack/getUserOption', async () => {
    try {
        let response = await axios.get('/User?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const appTimeTrackSlice = createSlice({
    name: 'getAllAppTimeTrack',

    initialState: {
        appTimeTrack: [],
        userOption: []
    },
    reducers: {
        removeAppTimeTrack: (state) => {
            state.appTimeTrack = [];
        },
        removeUserOption: (state) => {
            state.userOption = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAppTimeTrack.fulfilled, (state, action) => {
            state.appTimeTrack = action?.payload;
            return;
        });
        builder.addCase(getUserOption.fulfilled, (state, action) => {
            state.userOption = action?.payload;
            return;
        });
    }
});

export const { removeAppTimeTrack, removeUserOption } = appTimeTrackSlice.actions;
export default appTimeTrackSlice.reducer;
