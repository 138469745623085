// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getList = createAsyncThunk('crmSliceData/getList', async () => {
    try {
        let response = await axios.get('/List', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getListOption = createAsyncThunk('crmSliceData/getListOption', async () => {
    try {
        let response = await axios.get('/List?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteList = createAsyncThunk('crmSliceData/deleteList', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/List/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'List deleted successfully.');
        await dispatch(getListOption());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const emptyList = createAsyncThunk('crmSliceData/emptyList', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/List/empty-list/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'List deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createList = createAsyncThunk('crmSliceData/createList', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/List', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'List added successfully.');
        await dispatch(getListOption());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getMasterReport = createAsyncThunk('crmSliceData/getMasterReport', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Contact/master', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getSummaryReport = createAsyncThunk('crmSliceData/getSummaryReport', async (data) => {
    try {
        let response = await axios.post('/Contact/summary', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(JSON.parse(response?.data));
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createImportContact = createAsyncThunk('crmSliceData/createImportContact', async (data) => {
    try {
        let response = await axios.post('/Contact/import-contact', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Employee Add Successfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateList = createAsyncThunk('crmSliceData/updateList', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/List/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'List updated successfully.');
        await dispatch(getListOption());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateProductList = createAsyncThunk('crmSliceData/updateProductList', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Contact/add-product/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'List updated successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateRechurnList = createAsyncThunk('crmSliceData/updateRechurnList', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Contact/rechurn-list', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'List updated successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getContact = createAsyncThunk('crmSliceData/getContact', async (data) => {
    const query = queryString.stringify(data);

    try {
        let response = await axios.get('/Contact?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getTaskByContact = createAsyncThunk('crmSliceData/getTaskByContact', async (id) => {
    try {
        let response = await axios.get('/Task/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getContactHistory = createAsyncThunk('crmSliceData/getContactHistory', async (id) => {
    try {
        let response = await axios.get('/Contact/history/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getContactOption = createAsyncThunk('crmSliceData/getContactOption', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/Contact?option=true&' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteContact = createAsyncThunk('crmSliceData/deleteContact', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Contact/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // dispatch(getContact());
        toast.success(response?.data?.message || 'Contact deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createContact = createAsyncThunk('crmSliceData/createContact', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Contact', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Contact added successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateContact = createAsyncThunk('crmSliceData/updateContact', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Contact/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Contact updated successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getAssignMember = createAsyncThunk('crmSliceData/getAssignMember', async (data) => {
    const query = queryString.stringify(data);

    try {
        let response = await axios.get('/AssignMember?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getPerformance = createAsyncThunk('crmSliceData/getPerformance', async (data) => {
    const query = queryString.stringify(data);

    try {
        let response = await axios.get('/Contact/performance-report?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createAssignMember = createAsyncThunk('crmSliceData/createAssignMember', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/AssignMember', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Assign Member added successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateAssignMember = createAsyncThunk('crmSliceData/updateAssignMember', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/AssignMember/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Assign Member updated successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteAssignMember = createAsyncThunk('crmSliceData/deleteAssignMember', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/AssignMember/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // dispatch(getContact());
        toast.success(response?.data?.message || 'Assign Member deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getLeaderBoard = createAsyncThunk('crmSliceData/getLeaderBoard', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/Contact/leaderboard?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(JSON.parse(response?.data));
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getRecording = createAsyncThunk('crmSliceData/getRecording', async () => {
    try {
        let response = await axios.get('/Contact/recording', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteRecording = createAsyncThunk('crmSliceData/deleteRecording', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Contact/delete-recording/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Allowance deleted successfully.');
        await dispatch(getRecording());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createAutomation = createAsyncThunk('crmSliceData/createAutomation', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Automation', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'List added successfully.');
        // await dispatch(getListOption());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateAutomation = createAsyncThunk('crmSliceData/updateAutomation', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Automation/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Automation Rules updated successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getAutomationByList = createAsyncThunk('crmSliceData/getAutomationByList', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/Automation?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getAllAutomation = createAsyncThunk('crmSliceData/getAllAutomation', async () => {
    try {
        let response = await axios.get('/Automation/automation-by-list', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteAutomation = createAsyncThunk('crmSliceData/deleteAutomation', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Automation/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Automation deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getStageOption = createAsyncThunk('crmSliceData/getStageOption', async () => {
    try {
        let response = await axios.get('/Pipeline/option', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const crmSlice = createSlice({
    name: 'crmSliceData',
    initialState: {
        list: [],
        listOption: [],
        contact: [],
        assignMember: [],
        contactOption: [],
        taskByContact: [],
        contactHistory: [],
        masterReport: [],
        summaryReport: [],
        performance: [],
        leaderBoard: [],
        recording: [],
        automationByList: [],
        allAutomation: [],
        stageOption: []
    },
    reducers: {
        removeMember: (state, action) => {
            const updatedMemberData = state.assignMember.filter((member) => member.value !== action.payload);
            state.assignMember = updatedMemberData;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getList.fulfilled, (state, action) => {
            state.list = action?.payload;
            return;
        });
        builder.addCase(getListOption.fulfilled, (state, action) => {
            state.listOption = action?.payload;
            return;
        });
        builder.addCase(getStageOption.fulfilled, (state, action) => {
            state.stageOption = action?.payload;
            return;
        });
        builder.addCase(getContact.fulfilled, (state, action) => {
            state.contact = action?.payload;
            return;
        });
        builder.addCase(getAssignMember.fulfilled, (state, action) => {
            state.assignMember = action?.payload;
            return;
        });
        builder.addCase(getContactOption.fulfilled, (state, action) => {
            state.contactOption = action?.payload;
            return;
        });
        builder.addCase(getTaskByContact.fulfilled, (state, action) => {
            state.taskByContact = action?.payload;
            return;
        });
        builder.addCase(getAutomationByList.fulfilled, (state, action) => {
            state.automationByList = action?.payload;
            return;
        });
        builder.addCase(getContactHistory.fulfilled, (state, action) => {
            state.contactHistory = action?.payload;
            return;
        });
        builder.addCase(getMasterReport.fulfilled, (state, action) => {
            state.masterReport = action?.payload;
            return;
        });
        builder.addCase(getSummaryReport.fulfilled, (state, action) => {
            state.summaryReport = action?.payload;
            return;
        });
        builder.addCase(getPerformance.fulfilled, (state, action) => {
            state.performance = action?.payload;
            return;
        });
        builder.addCase(getLeaderBoard.fulfilled, (state, action) => {
            state.leaderBoard = action?.payload;
            return;
        });
        builder.addCase(getRecording.fulfilled, (state, action) => {
            state.recording = action?.payload;
            return;
        });
        builder.addCase(getAllAutomation.fulfilled, (state, action) => {
            state.allAutomation = action?.payload;
            return;
        });
    }
});

export const { removeMember } = crmSlice.actions;

export default crmSlice.reducer;
