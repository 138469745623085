// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getDeduction = createAsyncThunk('deductionSliceData/getDeduction', async () => {
    try {
        let response = await axios.get('/Deduction', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDeductionOption = createAsyncThunk('deductionSliceData/getDeductionOption', async () => {
    try {
        let response = await axios.get('/Deduction?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDeductionStatus = createAsyncThunk('deductionSliceData/getDeductionStatus', async () => {
    try {
        let response = await axios.get('/Deduction?status=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteDeduction = createAsyncThunk('deductionSliceData/deleteDeduction', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Deduction/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Deduction deleted successfully.');
        await dispatch(getDeduction());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createDeduction = createAsyncThunk('deductionSliceData/createDeduction', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Deduction', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Deduction added succesfully.');
        await dispatch(getDeduction());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDeduction = createAsyncThunk('deductionSliceData/updateDeduction', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Deduction/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Deduction updated succesfully.');
        await dispatch(getDeduction());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDeductionStatus = createAsyncThunk('deductionSliceData/updateDeductionStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/Deduction/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        dispatch(getDeduction());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deductionSlice = createSlice({
    name: 'deductionSliceData',
    initialState: {
        deduction: [],
        deductionoption: [],
        deductionStatus: []
    },
    extraReducers: (builder) => {
        builder.addCase(getDeduction.fulfilled, (state, action) => {
            state.deduction = action?.payload;
            return;
        });
        builder.addCase(getDeductionOption.fulfilled, (state, action) => {
            state.deductionoption = action?.payload;
            return;
        });
        builder.addCase(getDeductionStatus.fulfilled, (state, action) => {
            state.deductionStatus = action?.payload;
            return;
        });
    }
});

export default deductionSlice.reducer;
