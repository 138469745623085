// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getLoginLog = createAsyncThunk('getAllloginLogData/getLoginLog', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/SystemSetting/user-login-activity?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getLoginLogDetails = createAsyncThunk('getAllloginLogData/getLoginLogDetails', async (data) => {
    try {
        let response = await axios.get('/SystemSetting/user-login-activity/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getUserOption = createAsyncThunk('getAllloginLogData/getUserOption', async () => {
    try {
        let response = await axios.get('/User?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const loginLogSlice = createSlice({
    name: 'getAllloginLogData',

    initialState: {
        loginlog: [],
        loginLogDetail: [],
        userOption: []
    },
    reducers: {
        removeLoginLog: (state) => {
            state.loginlog = [];
        },
        removeUserOption: (state) => {
            state.userOption = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLoginLog.fulfilled, (state, action) => {
            state.loginlog = action?.payload;
            return;
        });
        builder.addCase(getLoginLogDetails.fulfilled, (state, action) => {
            state.loginLogDetail = action?.payload;
            return;
        });
        builder.addCase(getUserOption.fulfilled, (state, action) => {
            state.userOption = action?.payload;
            return;
        });
    }
});

export const { removeLoginLog, removeUserOption } = loginLogSlice.actions;
export default loginLogSlice.reducer;
