// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';
import queryString from 'query-string';

import { toast } from 'react-toastify';
import { getBranch } from '../../../CompanyMaster/Branch/store';
import { getEmployee } from '../../../EmployeeMaster/store';

export const getTermination = createAsyncThunk('getTerminationAllData/getTermination', async (data) => {
    try {
        let response = await axios.get('/Termination/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployees = createAsyncThunk('getTeamAndMemberAllData/getEmployees', async (data) => {
    const query = queryString.stringify(data);

    try {
        let response = await axios.get('/Employee/fetch-employee?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDepartmentOption = createAsyncThunk('getDepartmentAllData/getDepartmentOption', async () => {
    try {
        let response = await axios.get('/EmployeeDepartment?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteTermination = createAsyncThunk('getTerminationAllData/deleteTermination', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Termination/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Department delete Successfully.');

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createMember = createAsyncThunk('getTeamAndMemberAllData/createMember', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Employee/add-member', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Member added Successfully.');
        await dispatch(getEmployee());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createImportEmployee = createAsyncThunk('crmSliceData/createImportEmployee', async (data) => {
    try {
        let response = await axios.post('/Employee/import', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Employee Add Successfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBranch = createAsyncThunk('getTeamAndMemberAllData/createBranch', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Branch/add-branch', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Branch added Successfully.');
        await dispatch(getBranch());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateBranch = createAsyncThunk('getTeamAndMemberAllData/updateBranch', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Branch/edit-branch/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Termination updated succesfully.');
        await dispatch(getBranch());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateTermination = createAsyncThunk('getTerminationAllData/updateTermination', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Termination/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Termination updated succesfully.');
        // await dispatch(getAsset());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getTerminationRemarks = createAsyncThunk('getTerminationAllData/getTerminationRemarks', async (id) => {
    try {
        let response = await axios.get('/Termination/get-remarks/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateTerminationStatus = createAsyncThunk('getTerminationAllData/updateTerminationStatus', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Termination/request-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Termination update succesfully.');

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const teamAndMemberSlice = createSlice({
    name: 'getTeamAndMemberAllData',
    initialState: {
        termination: [],
        terminationRemarks: [],
        employees: []
    },
    reducers: {
        removeActiveClosed: (state) => {
            state.termination = [];
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getTermination.fulfilled, (state, action) => {
            state.termination = action?.payload;
            return;
        });
        builder.addCase(getEmployees.fulfilled, (state, action) => {
            state.employees = action?.payload;
            return;
        });
        builder.addCase(getTerminationRemarks.fulfilled, (state, action) => {
            state.terminationRemarks = action?.payload;
            return;
        });
    }
});
export const { removeActiveClosed } = teamAndMemberSlice.actions;

export default teamAndMemberSlice.reducer;
