// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getTask = createAsyncThunk('taskSliceData/getTask', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/Task?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteTask = createAsyncThunk('taskSliceData/deleteTask', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Task/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Task deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const createTask = createAsyncThunk('taskSliceData/createTask', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Task', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Task added successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateTask = createAsyncThunk('taskSliceData/updateTask', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Task/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Task updated successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateReschedule = createAsyncThunk('taskSliceData/updateReschedule', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Task/reschedule/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Task updated successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateReassign = createAsyncThunk('taskSliceData/updateReassign', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Task/reassign/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Task updated successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const isTaskDone = createAsyncThunk('taskSliceData/isTaskDone', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/Task/task-done/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Task updated successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const taskSlice = createSlice({
    name: 'taskSliceData',
    initialState: {
        task: []
    },
    extraReducers: (builder) => {
        builder.addCase(getTask.fulfilled, (state, action) => {
            state.task = action?.payload;
            return;
        });
    }
});

export default taskSlice.reducer;
