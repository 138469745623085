// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getTermination = createAsyncThunk('getTerminationAllData/getTermination', async (data) => {
    try {
        let response = await axios.get('/Termination/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployees = createAsyncThunk('getTerminationAllData/getEmployees', async () => {
    try {
        let response = await axios.get('/Employee/all-employee', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDepartmentOption = createAsyncThunk('getDepartmentAllData/getDepartmentOption', async () => {
    try {
        let response = await axios.get('/EmployeeDepartment?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteTermination = createAsyncThunk('getTerminationAllData/deleteTermination', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Termination/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Department delete Successfully.');

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createTermination = createAsyncThunk('getTerminationAllData/createTermination', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Termination', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Termination added Successfully.');
        await dispatch(getTermination(0));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateTermination = createAsyncThunk('getTerminationAllData/updateTermination', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Termination/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Termination updated succesfully.');
        // await dispatch(getAsset());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getTerminationRemarks = createAsyncThunk('getTerminationAllData/getTerminationRemarks', async (id) => {
    try {
        let response = await axios.get('/Termination/get-remarks/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateTerminationStatus = createAsyncThunk('getTerminationAllData/updateTerminationStatus', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Termination/request-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Termination update succesfully.');

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const terminationSlice = createSlice({
    name: 'getTerminationAllData',
    initialState: {
        termination: [],
        terminationRemarks: [],
        employees: []
    },
    reducers: {
        removeActiveClosed: (state) => {
            state.termination = [];
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getTermination.fulfilled, (state, action) => {
            state.termination = action?.payload;
            return;
        });
        builder.addCase(getEmployees.fulfilled, (state, action) => {
            state.employees = action?.payload;
            return;
        });
        builder.addCase(getTerminationRemarks.fulfilled, (state, action) => {
            state.terminationRemarks = action?.payload;
            return;
        });
    }
});
export const { removeActiveClosed } = terminationSlice.actions;

export default terminationSlice.reducer;
