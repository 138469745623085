// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getKycDocument = createAsyncThunk('getAllKycDocumentData/getKycDocument', async () => {
    try {
        let response = await axios.get('/KycDocMaster', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteKycDocument = createAsyncThunk('getAllKycDocumentData/deleteKycDocument', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/KycDocMaster/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Document deleted successfully.');
        await dispatch(getKycDocument());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createKycDocument = createAsyncThunk('getAllKycDocumentData/createKycDocument', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/KycDocMaster', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Document added succesfully.');
        await dispatch(getKycDocument());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateKycDocument = createAsyncThunk('getAllKycDocumentData/updateKycDocument', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/KycDocMaster/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Document updated succesfully.');
        await dispatch(getKycDocument());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateKycDocumentStatus = createAsyncThunk('getAllKycDocumentData/updateKycDocumentStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/KYCDocMaster/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(getKycDocument());
        toast.success(response?.data?.message || 'Status updated successfully !');
        return Promise.resolve(response);
    } catch {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const kycDocumentSlice = createSlice({
    name: 'getAllKycDocumentData',
    initialState: {
        kycDocument: []
    },
    extraReducers: (builder) => {
        builder.addCase(getKycDocument.fulfilled, (state, action) => {
            state.kycDocument = action?.payload;
            return;
        });
    }
});

export default kycDocumentSlice.reducer;
