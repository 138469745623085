// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';
// ** Toast Import
import { toast } from 'react-toastify';

export const getLeaveCategory = createAsyncThunk('leaveCategorySliceData/getLeaveCategory', async () => {
    try {
        let response = await axios.get('/LeaveCategory', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeaveCategoryOption = createAsyncThunk('leaveCategorySliceData/getLeaveCategoryOption', async () => {
    try {
        let response = await axios.get('/LeaveCategory?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteLeaveCategory = createAsyncThunk('leaveCategorySliceData/deleteLeaveCategory', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/LeaveCategory/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Leave Category deleted successfully.');
        await dispatch(getLeaveCategory());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createLeaveCategory = createAsyncThunk('leaveCategorySliceData/createLeaveCategory', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/LeaveCategory', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Leave Category added succesfully.');
        await dispatch(getLeaveCategory());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createLeaveRule = createAsyncThunk('getAllLeaveRules/createLeaveRule', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/LeaveRules', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Leave Category added succesfully.');
        await dispatch(getLeaveCategory());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateLeaveCategory = createAsyncThunk('leaveCategorySliceData/updateLeaveCategory', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/LeaveCategory/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Leave Category updated succesfully.');
        await dispatch(getLeaveCategory());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateLeaveCategoryStatus = createAsyncThunk('leaveCategorySliceData/updateLeaveCategoryStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/LeaveCategory/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        dispatch(getLeaveCategory());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const leaveCategorySlice = createSlice({
    name: 'leaveCategorySliceData',
    initialState: {
        leaveCategory: [],
        leaveCategoryOption: []
    },
    extraReducers: (builder) => {
        builder.addCase(getLeaveCategory.fulfilled, (state, action) => {
            state.leaveCategory = action?.payload;
            return;
        });
        builder.addCase(getLeaveCategoryOption.fulfilled, (state, action) => {
            state.leaveCategoryOption = action?.payload;
            return;
        });
    }
});

export default leaveCategorySlice.reducer;
