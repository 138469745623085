// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getDesignation = createAsyncThunk('getDesignationAllData/getDesignation', async () => {
    try {
        let response = await axios.get('/EmployeeDesignation', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDesignationOption = createAsyncThunk('getDesignationAllData/getDesignationOption', async () => {
    try {
        let response = await axios.get('/EmployeeDesignation?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteDesignation = createAsyncThunk('getDesignationAllData/deleteDesignation', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/EmployeeDesignation/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Designation delete Successfully.');
        await dispatch(getDesignation());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createDesignation = createAsyncThunk('getDesignationAllData/createDesignation', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/EmployeeDesignation', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Designation added Successfully.');
        await dispatch(getDesignation());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDesignation = createAsyncThunk('getDesignationAllData/updateDesignation', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/EmployeeDesignation/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Designation update Successfully.');
        await dispatch(getDesignation());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateDesignationStatus = createAsyncThunk('getDesignationAllData/updateDesignationStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/EmployeeDesignation/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getDesignation());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const designationSlice = createSlice({
    name: 'getDesignationAllData',
    initialState: {
        designation: [],
        designationOption: []
    },

    extraReducers: (builder) => {
        builder.addCase(getDesignation.fulfilled, (state, action) => {
            state.designation = action?.payload;
            return;
        });
        builder.addCase(getDesignationOption.fulfilled, (state, action) => {
            state.designationOption = action?.payload;
            return;
        });
    }
});

export default designationSlice.reducer;
