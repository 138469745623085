// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getDayAdjustment = createAsyncThunk('dayAdjustmentSliceData/getDayAdjustment', async () => {
    try {
        let response = await axios.get('/DayAdjustment', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getDayAdjustmentStatus = createAsyncThunk('dayAdjustmentSliceData/getDayAdjustmentStatus', async () => {
    try {
        let response = await axios.get('/DayAdjustment?status=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteDayAdjustment = createAsyncThunk('dayAdjustmentSliceData/deleteDayAdjustment', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/DayAdjustment/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'DayAdjustment deleted successfully.');
        await dispatch(getDayAdjustment());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createDayAdjustment = createAsyncThunk('dayAdjustmentSliceData/createDayAdjustment', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/DayAdjustment', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'DayAdjustment added succesfully.');
        await dispatch(getDayAdjustment());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDayAdjustment = createAsyncThunk('dayAdjustmentSliceData/updateDayAdjustment', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/DayAdjustment/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'DayAdjustment updated succesfully.');
        await dispatch(getDayAdjustment());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDayAdjustmentStatus = createAsyncThunk('dayAdjustmentSliceData/updateDayAdjustmentStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/DayAdjustment/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getDayAdjustment());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const dayAdjustmentSlice = createSlice({
    name: 'dayAdjustmentSliceData',
    initialState: {
        dayAdjustment: [],
        dayAdjustmentStatus: []
    },
    extraReducers: (builder) => {
        builder.addCase(getDayAdjustment.fulfilled, (state, action) => {
            state.dayAdjustment = action?.payload;
            return;
        });
        builder.addCase(getDayAdjustmentStatus.fulfilled, (state, action) => {
            state.dayAdjustmentStatus = action?.payload;
            return;
        });
    }
});

export default dayAdjustmentSlice.reducer;
