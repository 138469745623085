// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getDashboardCallCount = createAsyncThunk('dashboardSliceData/getDashboardCallCount', async () => {
    try {
        let response = await axios.get('/CRMDashboard/call-count', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDashboardAgentCount = createAsyncThunk('dashboardSliceData/getDashboardAgentCount', async () => {
    try {
        let response = await axios.get('/CRMDashboard/agent-count', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDashboardMembers = createAsyncThunk('dashboardSliceData/getDashboardMembers', async () => {
    try {
        let response = await axios.get('/CRMDashboard/members', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDashboardContactByList = createAsyncThunk('dashboardSliceData/getDashboardContactByList', async () => {
    try {
        let response = await axios.get('/CRMDashboard/contacts-by-list', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const crmDashboardSlice = createSlice({
    name: 'dashboardSliceData',
    initialState: {
        dashboardCount: [],
        dashboardContact: [],
        dashboardMember: [],
        dashboardAgent: []
    },
    extraReducers: (builder) => {
        builder.addCase(getDashboardCallCount.fulfilled, (state, action) => {
            state.dashboardCount = action?.payload;
            return;
        });
        builder.addCase(getDashboardAgentCount.fulfilled, (state, action) => {
            state.dashboardAgent = action?.payload;
            return;
        });
        builder.addCase(getDashboardMembers.fulfilled, (state, action) => {
            state.dashboardMember = action?.payload;
            return;
        });
        builder.addCase(getDashboardContactByList.fulfilled, (state, action) => {
            state.dashboardContact = action?.payload;
            return;
        });
    }
});

export default crmDashboardSlice.reducer;
