// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';
import queryString from 'query-string';

//** Toast Import
import { toast } from 'react-toastify';

// get all Over Time
export const getOverTime = createAsyncThunk('getAllOverTime/getOverTime', async (data) => {
    try {
        const { employeeId, monthNumber, year, requestStatus, ...queryParams } = data;
        const query = queryString.stringify(queryParams);
        let response = await axios.post(
            '/CorrectionInAttendance/get-request?' + query,
            { employeeId, monthNumber, year, requestStatus },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// get my Over Time
export const getMyOverTime = createAsyncThunk('getAllOverTime/getMyOverTime', async (data) => {
    try {
        const { monthNumber, year, requestStatus, ...queryParams } = data;
        const query = queryString.stringify(queryParams);
        let response = await axios.post(
            '/CorrectionInAttendance/get-my-request?' + query,
            { monthNumber, year, requestStatus },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getOverTimeOptions = createAsyncThunk('getAllOverTime/getOverTimeOptions', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/CorrectionInAttendance/inout-time?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// create all Over Time
export const createOverTime = createAsyncThunk('getAllOverTime/createOverTime', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/CorrectionInAttendance/request', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Correction added succesfully.');

        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// create my Over Time
export const createMyOverTime = createAsyncThunk('getAllOverTime/createMyOverTime', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/CorrectionInAttendance/my-request', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Overtime added succesfully.');

        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// update all Over Time
export const updateOverTime = createAsyncThunk('getAllOverTime/updateOverTime', async (data) => {
    try {
        let response = await axios.put('/CorrectionInAttendance/request/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Overtime updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// update my Over Time
export const updateMyOverTime = createAsyncThunk('getAllOverTime/updateMyOverTime', async (data) => {
    try {
        let response = await axios.put('/CorrectionInAttendance/my-request/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Overtime updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// delete any Over Time
export const deleteOverTime = createAsyncThunk('getAllOverTime/deleteOverTime', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/CorrectionInAttendance/request/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Overtime deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// delete my Over Time
export const deleteMyOverTime = createAsyncThunk('getAllOverTime/deleteMyOverTime', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/CorrectionInAttendance/my-request/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Overtime deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateRequestRemarks = createAsyncThunk('getAllOverTime/updateRequestRemarks', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/CorrectionInAttendance/request-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const OverTimeSlice = createSlice({
    name: 'getAllOverTime',
    initialState: {
        overTime: [],
        myOverTime: [],
        overTimeOption: []
    },
    reducers: {
        removeOverTime: (state) => {
            state.overTime = [];
        },
        removeMyOverTime: (state) => {
            state.myOverTime = [];
        },
        removeTime: (state) => {
            state.overTimeOption = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getOverTime.fulfilled, (state, action) => {
            state.overTime = action?.payload;
            return;
        });
        builder.addCase(getMyOverTime.fulfilled, (state, action) => {
            state.myOverTime = action?.payload;
            return;
        });
        builder.addCase(getOverTimeOptions.fulfilled, (state, action) => {
            state.overTimeOption = action?.payload;
            return;
        });
    }
});

export const { removeOverTime, removeTime, removeMyOverTime } = OverTimeSlice.actions;
export default OverTimeSlice.reducer;
