// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getPipeline = createAsyncThunk('pipelineSliceData/getPipeline', async () => {
    try {
        let response = await axios.get('/Pipeline', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getPipelineOption = createAsyncThunk('pipelineSliceData/getPipelineOption', async () => {
    try {
        let response = await axios.get('/List?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deletePipeline = createAsyncThunk('pipelineSliceData/deletePipeline', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Pipeline/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Pipeline deleted successfully.');
        await dispatch(getPipeline());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createPipeline = createAsyncThunk('pipelineSliceData/createPipeline', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Pipeline', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Pipeline added successfully.');
        await dispatch(getPipeline());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updatePipeline = createAsyncThunk('pipelineSliceData/updatePipeline', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Pipeline/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Pipeline updated successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updatePipelineTasks = createAsyncThunk('pipelineSliceData/updatePipelineTasks', async (data, { dispatch }) => {
    console.log('data', data);
    try {
        let response = await axios.put('/Contact/change-stage', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Pipeline updated successfully.');
        await dispatch(getPipeline());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const pipelineSlice = createSlice({
    name: 'pipelineSliceData',
    initialState: {
        pipeline: []
    },
    extraReducers: (builder) => {
        builder.addCase(getPipeline.fulfilled, (state, action) => {
            state.pipeline = action?.payload;
            return;
        });
    }
});

export default pipelineSlice.reducer;
