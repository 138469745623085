// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';
// ** API Imports
import axios from '@src/service/axios';

//** Toast Import
import { toast } from 'react-toastify';

export const getRequestRemarks = createAsyncThunk('getRequestRemarksData/getRequestRemarks', async (id) => {
    try {
        let response = await axios.get('/CorrectionInAttendance/remarks-history/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getShiftChangeRemarks = createAsyncThunk('getRequestRemarksData/getRequestRemarks', async (id) => {
    try {
        let response = await axios.get('/ShiftChange/remarks-history/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getCompOffRemarks = createAsyncThunk('getRequestRemarksData/getCompOffRemarks', async (id) => {
    try {
        let response = await axios.get('/CompOffRequest/remarks-history/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateRequestRemarks = createAsyncThunk('getRequestRemarksData/updateRequestRemarks', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/CorrectionInAttendance/request-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBulkRequestRemarks = createAsyncThunk('getRequestRemarksData/updateBulkRequestRemarks', async (data) => {
    try {
        let response = await axios.put(`/CorrectionInAttendance/bulk-request-status?RequestType=${data.queryParams}`, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const requestRemarksSlice = createSlice({
    name: 'getRequestRemarksData',
    initialState: {
        requestRemarks: []
    },
    extraReducers: (builder) => {
        builder.addCase(getRequestRemarks.fulfilled, (state, action) => {
            state.requestRemarks = action?.payload;
            return;
        });
    }
});

export default requestRemarksSlice.reducer;
