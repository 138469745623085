// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getDepartment = createAsyncThunk('getDepartmentAllData/getDepartment', async () => {
    try {
        let response = await axios.get('/EmployeeDepartment', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDepartmentOption = createAsyncThunk('getDepartmentAllData/getDepartmentOption', async () => {
    try {
        let response = await axios.get('/EmployeeDepartment?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteDepartment = createAsyncThunk('getDepartmentAllData/deleteDepartment', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/EmployeeDepartment/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Department delete Successfully.');
        await dispatch(getDepartment());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createDepartment = createAsyncThunk('getDepartmentAllData/createDepartment', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/EmployeeDepartment', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Department added Successfully.');
        await dispatch(getDepartment());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDepartment = createAsyncThunk('getDepartmentAllData/updateDepartment', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/EmployeeDepartment/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Department update Successfully.');
        await dispatch(getDepartment());
        return Promise.resolve(response);
    } catch (error) {
        toast.error(error?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(error);
    }
});

export const updateDepartmentStatus = createAsyncThunk('getDepartmentAllData/updateDepartmentStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/EmployeeDepartment/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getDepartment());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const departmentSlice = createSlice({
    name: 'getDepartmentAllData',
    initialState: {
        department: [],
        departmentOption: []
    },

    extraReducers: (builder) => {
        builder.addCase(getDepartment.fulfilled, (state, action) => {
            state.department = action?.payload;
            return;
        });
        builder.addCase(getDepartmentOption.fulfilled, (state, action) => {
            state.departmentOption = action?.payload;
            return;
        });
    }
});

export default departmentSlice.reducer;
