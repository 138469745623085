// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getUser = createAsyncThunk('userSliceData/getUser', async () => {
    try {
        let response = await axios.get('/user', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const downloadTxtFile = createAsyncThunk('userSliceData/downloadTxtFile', async (id) => {
    try {
        let response = await axios.get('/user/security-token/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getRole = createAsyncThunk('userSliceData/getRole', async () => {
    try {
        let response = await axios.get('/role', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteUser = createAsyncThunk('userSliceData/deleteUser', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/user/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'User deleted successfully.');
        await dispatch(getUser());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createUser = createAsyncThunk('userSliceData/createUser', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/user', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'User created successfully.');
        // await dispatch(getUser());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateUser = createAsyncThunk('userSliceData/updateUser', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/user/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'User updated successfully.');
        // await dispatch(getUser());
        return Promise.resolve(response);
    } catch {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateUserStatus = createAsyncThunk('userSliceData/updateUserStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/user/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(getUser());
        toast.success(response?.data?.message || 'User status updated successfully !');
        return Promise.resolve(response);
    } catch {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getRoleOption = createAsyncThunk('userSliceData/getRoleOption', async () => {
    try {
        let response = await axios.get('/role?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getUserId = createAsyncThunk('userSliceData/getUserbyId', async (id) => {
    try {
        let response = await axios.get('/user/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

///** User Password Reset Api

export const updateUserPassword = createAsyncThunk('userSliceData/updateUserPassword', async (data) => {
    try {
        let response = await axios.put('/Authentication/user-wise-reset-password/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'User Password reset successfully!');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getUserRandomPassword = createAsyncThunk('userSliceData/getUserRandomPassword', async () => {
    try {
        let response = await axios.get('/Authentication/generate-password', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const userSlice = createSlice({
    name: 'userSliceData',
    initialState: {
        user: [],
        roleOptions: [],
        randomPassword: {}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.user = action?.payload;
            return;
        });
        builder.addCase(getRoleOption.fulfilled, (state, action) => {
            state.roleOptions = action?.payload;
            return;
        });
        builder.addCase(getUserRandomPassword.fulfilled, (state, action) => {
            state.randomPassword = action?.payload;
            return;
        });
    }
});

export default userSlice.reducer;
